/**
 * 登录模块的ajax函数
 */

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 引入设置好的axios
import request from '@/utils/request'

// 获取登录二维码
export const getQrcode = (params) => {
  return request({
    url: '/api/login/qrcode',
    method: "get",
    params
  })
}

// 获取验证码
export const getCodeApi = (params) => {
  return request({
    url: '/api/user/getCode',
    method: "get",
    params
  })
}

// 手机号登录
export const loginApi = (params) => {
  return request({
    url: '/api/user/phoneLogin',
    method: "get",
    params
  })
}

// 获取协议
export const getProtocol = (params) => {
  return request({
    url: '/api/getPage',
    method: "get",
    params
  })
}

// h5确认登录获取用户信息
export const qrLogin = (params) => {
  return request({
    url: '/api/user/qrLogin',
    method: "get",
    params
  })
}

// 确认用户是否已扫码的轮询接口
export const qrLoginCheck = (params) => {
  return request({
    url: '/api/user/qrLoginCheck',
    method: "get",
    params
  })
} 
