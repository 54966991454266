<template>
  <div class="layout scrollbar" id="layout">
    <!-- 顶部的导航 -->
    <top-nav></top-nav>

    <!-- 出口 -->
    <div class="output">
      <!-- <keep-alive> -->
      <router-view></router-view>
      <!-- </keep-alive> -->
    </div>

    <!-- 底部信息 -->
    <footer-info v-if="footerShow"></footer-info>

    <!-- im -->
    <!-- <im-chat></im-chat> -->

    <!-- 侧边栏 -->
    <Sidebar></Sidebar>
  </div>
</template>

<script>
// 引入组件
import TopNav from "./TopNav.vue";
import FooterInfo from "./FooterInfo.vue";
import imChat from "@/components/imChat/imChat.vue";
import Sidebar from "@/views/layout/Sidebar.vue";

export default {
  components: {
    TopNav,
    FooterInfo,
    imChat,
    Sidebar,
  },

  data() {
    return {
      footerShow: true,
    };
  },

  watch: {
    $route: {
      handler(to, from) {
        if (to.name === "combinedQuery") {
          this.footerShow = false;
        } else {
          this.footerShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    backTop() {
      console.log("smooth");

      window.scrollTop();
    },
  },
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 550px) {
  .layout {
    width: 100%;
    height: 100%;
    min-width: 1400px;
    min-height: 100%;
    overflow-y: scroll;
    overflow-x: scroll;
    .output {
      background-color: #f9f9f9;
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 500px) {
  .layout {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    .output {
      background-color: #f9f9f9;
    }
  }
}
</style>
