/**
 * ajax工具函数
 */

/* 引入需要的函数 */
import axios from 'axios' // 引入ajax函数
import { Message } from 'element-ui'  // 引入element的消息组件
import local from '@/utils/local'// 引入local工具函数
import router from '@/router' // 引入路由 自动找到index.js
// import { getNewTokens } from '@/api/common.js'

// 创建一个axios实例 设置请求地址和请求超时时间
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url

  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000000000 // request timeout
})
console.log('baseUrl', process.env.VUE_APP_BASE_API);

// 请求拦截器
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // console.log("请求config", config);
    const userInfo = local.get("userInfo") || { id: "" };
    if (config.method == 'get') {
      if (config.params) {
        config.params['userId'] = userInfo.id;
      }
    } else if (config.method == 'post') {
      if (config.data) {
        config.data['userId'] = userInfo.id
      }
    }
    const timestamp = Date.parse(new Date()) / 1000;    // 当前时间戳
    config.headers['timestamp'] = timestamp;    // 往请求头添加时间戳
    // 从本地取出token
    let token = local.get('token')
    // console.log('token>>>', token)
    if (token) {
      config.headers['token'] = token // 往请求头添加token
    }
    return config
  },
  error => {
    // do something with request error
    console.log('请求错误', error) // for debug
    return Promise.reject(error)
  }
)
// 白名单 
const whiteList = ['/api/user/qrLoginCheck']
// 响应拦截器
service.interceptors.response.use(
  async res => {
    // console.log('响应拦截器>>>', res);

    //我们可以定义一个标准响应体，比如：{code=401,msg='token已过期',data:null}，当收到token过期的响应就要进行token刷新了
    // if (res.status == 401) {
    //   router.push('/login');
    // } else {
    //   if (res.status == 200 || res.status == 422) {
    // return res.data;
    //   } else if (res.status == 403) {
    //     Message({
    //       message: '当前登录已过期, 请重新登录!',
    //       type: 'error',
    //       duration: 2000
    //     })
    //     local.remove("token");
    //     router.push('/login');
    //   } else if (res.code) {
    //     Message({
    //       message: res.data.msg || 'Error',
    //       type: 'error',
    //       duration: 2000
    //     })

    //     return Promise.reject(new Error(res.data.msg || 'Error'))
    //   }
    // }
    // 403：未登录状态
    if (res.status == 403) {
      console.log('this.$router', this.$router);

      // if()
      // Message({
      //   message: data.msg,
      //   type: 'error',
      //   duration: 2000
      // })
      // local.remove('token')
      // local.remove('userInfo')
      // router.push('/login');
    } else if (res.status == 200) {
      const data = res.data
      const url = res.config.url
      // 如果不是白名单 且 返回的code是-1 则弹出错误提示信息
      if (data.code == -1 && whiteList.indexOf(url) === -1) {
        Message({
          message: data.msg,
          type: 'error',
          duration: 2000
        })
        // console.log('666', data);
      }
      return data
    }
  },

  error => {
    // console.log('响应错误' + error.response.data.msg) // for debug
    if (router.history.current.fullPath != '/login') {
      Message({
        message: error.response.data.msg || error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    // 如果错误代码是403 证明未登录状态，则跳转到登录页面
    if (error.response.status === 403) {
      local.remove('token')
      local.remove('userInfo')
      router.push('/login')
    }
    return Promise.reject(error)// 让Promise状态从进行中 -> 失败
  }
)

export default service
