const filters = {
  noVoid: function (value) {
    if (value && value != '-1') {
      return value
    } else {
      return '--'
    }

  },
  noVoidNum: function (value) {
    if (value && value != '-1') {
      return value
    } else {
      return '0'
    }

  },
  timeFormat: function (value) {
    if (value && value != '-1') {
      return value.split(" ")[0]
    } else {
      return '--'
    }

  },
  wanCost: function (value) {
    if (value && value != '-1') {
      return (Number(value) / 10000).toFixed(2) + '万元'
    } else {
      return '--'
    }

  },
  formatTitle(title, searchName) {
    if (!searchName) return `<span>${title}</span>`;
    // 将搜索词拆分为单个字符的正则表达式
    const chars = searchName
      .split("")
      .map((char) => this.escapeRegexChar(char));
    const regexStr = `(${chars.join("|")})`; // 构建正则表达式字符串
    const reg = new RegExp(regexStr, "gi"); // 创建正则表达式对象，'gi' 表示全局不区分大小写

    // 使用replace方法和回调函数替换匹配到的字符
    return title.replace(reg, (match) => {
      // 返回被<span>标签包裹且设置为红色的匹配字符
      return `<span style="color: #FF0000;">${match}</span>`;
    });
  },
  escapeRegexChar(char) {
    return `\\${char}`;
  },
}
export default filters;