<template>
  <div class="person_layout">
    <div :class="['left_menu', isCollapse ? 'collapse' : '']">
      <div class="logo_box">
        <img
          @click="toHome"
          class="logo"
          :src="
            isCollapse
              ? 'https://www.zizhihelp.com/upload/logo_round.png'
              : 'https://www.zizhihelp.com/upload/static/web/mine_logo.png'
          "
          alt=""
        />
      </div>

      <el-menu
        :default-active="defaultActivePath"
        class="el-menu-vertical-demo"
        text-color="#fff"
        active-text-color="#fff"
        router
        :collapse="isCollapse"
      >
        <el-menu-item
          v-for="(item, index) in menuList"
          :key="item.name"
          :index="item.path"
        >
          <img
            :src="`https://www.zizhihelp.com/upload/static/web/${item.iconUrl}.png`"
            class="menu_icon"
            alt=""
          />
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>

    <div class="right_content scrollbar">
      <div class="top_nav">
        <div class="top_nav_left">
          <img
            @click="isCollapse = !isCollapse"
            class="icon_collapse cursorP"
            src="https://www.zizhihelp.com/upload/static/web/mine_fenye.png"
            alt=""
          />

          <div>
            {{ $route.meta.title }}
          </div>
        </div>

        <!-- <div class="user_info">
          <div class="name">{{ userInfo.nickname }}</div>

          <img
            class="avatar"
            :src="
              userInfo.headimgurl ||
              'https://www.zizhihelp.com/upload/static/web/mine_touxiang.png'
            "
            alt=""
          />

          <div class="user_children">
            <div class="user_children_item" @click="logout">退出登录</div>
          </div>
        </div> -->

        <el-dropdown>
          <div class="user_info" @click="toPersonCenter">
            <div class="name">{{ userInfo.nickname }}</div>

            <el-avatar :size="38" :src="userInfo.headimgurl" class="ml10">
              <img
                src="https://www.zizhihelp.com/upload/static/web/mine_touxiang.png"
              />
            </el-avatar>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native.stop="logout">
              退出登录
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="gap"></div>

      <keep-alive>
        <router-view class="router_view"></router-view>
      </keep-alive>
    </div>
  </div>
</template>

<script>
import local from "@/utils/local.js";

export default {
  data() {
    return {
      menuList: [
        {
          name: "会员中心",
          path: "/personalCenter/vipCenter",
          iconUrl: "mine_huiyuan",
        },
        {
          name: "订单记录",
          path: "/personalCenter/orderCenter",
          iconUrl: "mine_dingdan",
        },
        {
          name: "我的公司",
          path: "/personalCenter/myCompany",
          iconUrl: "mine_gongsi",
        },
        {
          name: "我的推广",
          path: "/personalCenter/myPromotion",
          iconUrl: "mine_tuiguang",
        },
        {
          name: "分销中心",
          path: "/personalCenter/distributionCenter",
          iconUrl: "mine_fenxiao",
        },
        {
          name: "聊天设置",
          path: "/personalCenter/chatSettings",
          iconUrl: "mine_liaotian",
        },
        {
          name: "账号设置",
          path: "/personalCenter/accountSettings",
          iconUrl: "mine_zhanghao",
        },
      ],
      defaultActivePath: "/personalCenter/vipCenter",
      isCollapse: false,
      userInfo: {},
    };
  },

  watch: {
    $route(newVal) {
      this.defaultActivePath = newVal.path;
    },
  },

  methods: {
    /* 操作 */
    // 跳转首页
    toHome() {
      this.$router.push("/home");
    },
    // 跳转账号设置
    toPersonCenter() {
      this.$router.push("/personalCenter/accountSettings");
    },
    // 退出登录
    logout() {
      this.$confirm("确定退出登录？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          local.clear();
          this.$router.push("/login");
        })
        .catch(() => {});
    },

    /* 初始化 */
    __init__() {
      this.userInfo = local.get("userInfo");
      if (!this.userInfo.headimgurl) {
        this.userInfo.headimgurl =
          "https://www.zizhihelp.com/upload/static/web/mine_touxiang.png";
      }

      this.defaultActivePath = this.$route.path;

      this.$bus.$on("userInfoChange", () => {
        this.userInfo = local.get("userInfo");
      });
    },
  },

  mounted() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.person_layout {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .left_menu {
    width: 280px;
    padding-right: 40px;
    height: 100%;
    background: url("https://www.zizhihelp.com/upload/static/web/mine_beijing.png")
      no-repeat;
    background-size: contain;
    background-color: #1b2534;
    transition: all 0.3s ease-in-out;

    .logo_box {
      padding: 20px 0px;
      margin-bottom: 30px;
      text-align: center;

      .logo {
        height: 40px;
        cursor: pointer;
      }
    }

    .el-menu {
      background-color: transparent;
      border-right: none;
    }

    .menu_icon {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }

    .el-menu-item.is-active {
      background-color: rgba(255, 255, 255, 0.29);
      border-radius: 4px;
    }

    .el-menu-item:focus,
    .el-menu-item:hover {
      background-color: rgba(255, 255, 255, 0.29);
      border-radius: 4px;
    }
  }

  .collapse {
    width: 105px;
  }

  .right_content {
    flex: 1;
    background-color: #fff;
    margin-left: -40px;
    height: 100%;
    overflow-y: scroll;
    border-radius: 30px 0px 0px 30px;

    .top_nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px;
      color: #333;
      font-size: 16px;

      .top_nav_left {
        display: flex;
        align-items: center;
        .icon_collapse {
          width: 20px;
          height: 16px;
          margin-right: 12px;
        }
      }

      .user_info {
        display: flex;
        align-items: center;
        position: relative;

        .avatar {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin-left: 15px;
          cursor: pointer;
        }

        .user_children {
          display: none;
          position: absolute;
          top: 40px;
          right: 0px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0px 0px 4px 0px rgba(153, 153, 153, 0.5);

          .user_children_item {
            display: flex;
            padding: 20px 40px;
            white-space: nowrap;
            cursor: pointer;
            font-size: 14px;
            border-bottom: 1px solid #ddd;

            &:last-child {
              border-bottom: none;
            }
            &:hover {
              background-color: #d7edfb;
              &:nth-child(1) {
                border-radius: 10px 10px 0px 0px;
              }
              &:nth-child(2) {
                border-radius: 0px 0px 10px 10px;
              }
            }
          }
        }

        &:hover {
          .user_children {
            display: block;
          }
        }
      }
    }
    .gap {
      height: 10px;
      background: #f8fcff;
    }
  }

  .router_view {
    padding: 40px;
  }
}
</style>
