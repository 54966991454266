import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入重置样式
import './assets/css/normalize.css'
import './assets/css/reset.css'
import './assets/css/common.css'

// 引入iconfont.css
import './assets/fonts/iconfont.css'


// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts';

// 引入elemen-ui的js和css
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入rem自适应的js
// import '@/utils/rem.js'
// 引入dialogDrag指令
import '@/components/Dialog/dialog.js'
// 引入弹窗组件
import custom_dialog from '@/components/Dialog/custom_dialog.vue'

// 引入过滤器
import filters from '@/filters/index.js';

// 引入store和bus
import { store, bus } from '@/store/index.js'

// 引入自定义指令
import '@/directive/index.js'

// 在vue中注入ElementUI
Vue.use(ElementUI, { size: 'small' })

// 将echarts挂在原型上
Vue.prototype.$echarts = echarts


// 创建乱传的中介
Vue.prototype.$bus = new Vue()
//全局挂载store
Vue.prototype.$store = store
Vue.prototype.$bus = bus

Vue.config.productionTip = false  //关闭生产提示-就是一些没什么用的警告信息

Vue.component('customDialog', custom_dialog)

for (let key in filters) {
  Vue.filter(key, filters[key]);
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
