// store.js
import Vue from 'vue'
import Vuex from 'vuex'
import local from '@/utils/local.js'
import { fetchEventSource } from '@microsoft/fetch-event-source';

Vue.use(Vuex);
const bus = new Vue()


const store = new Vuex.Store({
  state: {
    socket: null, // websocket连接对象
    messages: [], // im-消息列表
    combinedQueryParams: local.get('combinedQueryParams') || {}, // 组合查询参数
  },
  mutations: {
    // 设置WebSocket连接对象
    SET_SOCKET(state, socket) {
      state.socket = socket;
    },
    // 添加消息到列表中
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    // 保存组合查询的参数
    SET_PARAMS(state, data) {
      state.combinedQueryParams = data;
      local.set('combinedQueryParams', data);
    },
  },
  actions: {
    // 连接WebSocket服务器
    connectWebSocket({ commit }, url) {
      // 创建WebSocket连接
      const socket = new WebSocket(url);
      socket.addEventListener('open', () => {
        commit('SET_SOCKET', socket);
        console.log('全局websocket连接成功')
      });
      // 添加消息事件监听器
      socket.addEventListener('message', (res) => {
        console.log('收到服务器内容：', res.data)
        if (res.data == 'OK') return
        let temp = local.get('newMsg') || []
        temp.push(JSON.parse(res.data))
        local.set('newMsg', temp)
        bus.$emit('newMsg', temp);
      });

      // 添加错误和关闭事件监听器
      socket.addEventListener('error', (err) => {
        console.log('WebSocket连接失败：', err);
        commit('SET_SOCKET', null);
      });
      socket.addEventListener('close', (err) => {
        console.log('WebSocket关闭连接：', err);
        // bus.$emit('websocketClose');
        commit('SET_SOCKET', null);
      });
    },

    // 发送消息到WebSocket服务器
    senWebSocket(state, data) {
      if (!state.state.socket) return bus.$emit('websocketErr');
      state.state.socket.send(data)
    },

    // 保存组合查询的参数
    setCombinedQueryParams({ commit }, data) {
      commit('SET_PARAMS', data);
    },

    // 连接sse
    connectSSE({ commit }, params) {
      const token = local.get('token') || ''
      if (!token) return
      const ctrl = new AbortController();
      const { url, data } = params
      fetchEventSource(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', token: token },
        // 传参必须保证是json
        body: JSON.stringify(data),
        signal: ctrl.signal,
        openWhenHidden: true, // 切换标签页时连接不关闭
        onopen: (response) => { console.log('Connection opened', response) },
        onmessage: (event) => {
          // console.log('onmessage', event.data)
          bus.$emit('sseMsg', event.data);
        },
        onclose: () => { console.log('Connection closed') },
        onerror: (error) => {
          console.log('Error:', error)
          throw error
        }
      })
    },
  }
});


export {
  store,
  bus
}
