<template>
  <div class="app">
    <!-- 出口 -->
    <keep-alive :include="include">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import local from "@/utils/local.js";

export default {
  data() {
    return { include: ["home"] };
  },

  methods: {
    // websocket初始化连接
    websocketInit() {
      // 创建websocket链接
      const token = local.get("token") || "";
      if (!token) return;
      const platform = "pc";
      console.log(`wss://www.zizhihelp.com/api/websocket/${token}/${platform}`);

      this.$store.dispatch(
        "connectWebSocket",
        `wss://www.zizhihelp.com/api/websocket/${token}/${platform}`
      );
    },
    // 心跳检测
    checkSocketHeartbeat() {
      /* 每隔一分钟检查一次是否有websocket连接，若没有则重连websocket */
      this.timer = setInterval(() => {
        this.$store.dispatch("senWebSocket", "PING");
      }, 60000);
    },
    // 处理发送消息失败事件
    handleSendErr() {
      console.log('handleSendErr');
      
      const timer = setTimeout(() => {
        this.websocketInit();
        const timer1 = setTimeout(() => {
          const webscoketParams = local.get("webscoketParams") || null;
          if (webscoketParams) {
            this.$store.dispatch("senWebSocket", webscoketParams);
            local.remove("webscoketParams");
          }
          clearTimeout(timer);
          clearTimeout(timer1);
        }, 1000);
      }, 10000);
    },
  },

  mounted() {
    this.websocketInit();
    this.checkSocketHeartbeat();
    this.$bus.$on("websocketErr", this.handleSendErr);
  },
};
</script>

<style lang="less" scoped>
.app {
  width: 100%;
  height: 100%;

  /deep/.scrollbar {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }

  /deep/.el-table__body-wrapper {
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #e1e2e5;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ffffff;
    }
  }
}
</style>

<style lang="less">
.el-radio__original {
  // display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
}
</style>
