import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入local工具函数
import local from '@/utils/local'

// 引入页面
import Login from '../views/login/Login.vue'
// import Home from '../views/home/home.vue'
import Layout from '../views/layout/Layout.vue'
import singleLayout from '../views/layout/singleLayout.vue'
import personalLayout from '@/views/personalCenter/layout.vue'



Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [

  /*  {
    // 无二级路由配置示例
     path: '/shop',
     component: Layout,
     meta: { title: "店铺管理" },
     children: [
       { path: '', component: Shop }
     ]
   }, */


  // 根目录重定向到登录页面
  { path: '/', redirect: '/home' },
  // { path: '/', component: Home },
  {
    path: '/home',
    component: Layout,
    meta: { title: "首页", index: 0 },
    children: [
      {
        path: '', name: 'home',
        component: () => import('../views/home/home.vue'),
        meta: { title: "首页", index: 0 }
      }
    ]
  },


  // 登录
  { path: '/login', component: Login },

  // 心愿管理
  // {
  //     path: '/wishlist',
  //     component: Layout,
  //     meta: { title: "心愿管理" },
  //     children: [
  //         { path: '', name: 'wishlist', component: () => import('../views/wishBottleList/WishBottleList.vue') }
  //     ]
  // },

  // 分项查询
  {
    path: '/itemizedQuery/:name',
    component: Layout,
    meta: { title: "分项查询", index: 1 },
    children: [
      {
        path: '', name: 'itemizedQuery',
        component: () => import('@/views/itemizedQuery/index.vue'),
        meta: { title: "分项查询", index: 1 }
      }
    ]
  },
  // 组合查询
  {
    path: '/combinedQuery',
    component: Layout,
    meta: { title: "组合查询", index: 2 },
    children: [
      {
        path: '', name: 'combinedQuery',
        component: () => import('@/views/combinedQuery/index.vue'),
        meta: { title: "组合查询", index: 2 }
      },
      {
        path: '/combinedQuery/list', name: 'combinedQueryList',
        component: () => import('@/views/combinedQuery/list.vue'),
        meta: { title: "检索解结果", index: 2 }
      }
    ]
  },
  // 公司详情
  {
    path: '/companyDetails/:name',
    component: Layout,
    meta: { title: "公司详情" },
    children: [
      { path: '', name: 'companyDetails', component: () => import('@/views/companyDetails/index.vue') }
    ]
  },
  // 详情页面
  {
    path: '/details',
    component: Layout,
    meta: { title: "详情" },
    children: [
      {
        path: '/details/performance/:id',
        name: 'performanceDetail',
        component: () => import('@/views/detailsPage/performanceDetails.vue'),
        meta: { title: "业绩详情" },
      },
      {
        path: '/details/certification/:id',
        name: 'certificationDetails',
        component: () => import('@/views/detailsPage/certificationDetails.vue'),
        meta: { title: "资质详情" },
      },
      {
        path: '/details/person/:id',
        name: 'personDetails',
        component: () => import('@/views/detailsPage/personDetails.vue'),
        meta: { title: "人员详情" },
      },
      {
        path: '/details/bids/:id',
        name: 'bidsDetails',
        component: () => import('@/views/detailsPage/bidsDetails.vue'),
        meta: { title: "招标详情" },
      },
      {
        path: '/details/honor/:id',
        name: 'honorDetails',
        component: () => import('@/views/detailsPage/honorDetails.vue'),
        meta: { title: "荣誉详情" },
      },
      {
        path: '/details/integrity/:id',
        name: 'integrityDetails',
        component: () => import('@/views/detailsPage/integrityDetails.vue'),
        meta: { title: "诚信详情" },
      },
      {
        path: '/details/evaluate/:id',
        name: 'evaluateDetails',
        component: () => import('@/views/detailsPage/evaluateDetails.vue'),
        meta: { title: "评价详情" },
      },
      {
        path: '/details/owner',
        name: 'ownerDetails',
        component: () => import('@/views/detailsPage/ownerDetails.vue'),
        meta: { title: "评价详情" },
      },
    ]
  },
  // 隐私协议
  {
    path: '/protocol',
    component: Layout,
    meta: { title: "隐私协议" },
    children: [
      {
        path: '',
        component: () => import('@/views/login/protocol.vue')
      }
    ]
  },
  // 登录成功-H5 公众号使用
  {
    path: '/loginSuccess',
    component: singleLayout,
    meta: { title: "登录成功" },
    children: [
      {
        path: '',
        component: () => import('@/views/login/sucess.vue')
      }
    ]
  },
  // 企业入驻
  // {
  //   path: '/settlement',
  //   redirect: '/settlement/index',
  //   component: Layout,
  //   meta: { title: "企业入驻" },
  //   children: [
  //     {
  //       path: '/settlement/index',
  //       name: 'settlementList',
  //       component: () => import('@/views/settlement/index.vue')
  //     },
  //     {
  //       path: '/settlement/add',
  //       name: 'addSettlement',
  //       component: () => import('@/views/settlement/add.vue')
  //     }
  //   ]
  // },
  // 四库专查
  {
    path: '/fourthLibrary',
    component: Layout,
    meta: { title: "四库专查", index: 3 },
    children: [
      {
        path: '',
        name: 'fourthLibrary',
        component: () => import('@/views/fourthLibrary/index.vue'),
        meta: { title: "四库专查", index: 3 }
      },
      {
        path: '/fourthLibrary/details/:id',
        name: 'fourthLibraryDetails',
        component: () => import('@/views/fourthLibrary/details.vue'),
        meta: { title: "四库专查", index: 3 }
      }
    ]
  },
  // 水利专查
  {
    path: '/waterConservancy',
    component: Layout,
    meta: { title: "水利专查", index: 4 },
    children: [
      {
        path: '',
        name: 'waterConservancy',
        component: () => import('@/views/waterConservancy/index.vue'),
        meta: { title: "水利专查", index: 4 }
      },
      {
        path: '/waterConservancy/details/:id',
        name: 'waterConservancyDetails',
        component: () => import('@/views/waterConservancy/details.vue'),
        meta: { title: "水利专查", index: 4 }
      }
    ]
  },
  // 公路专查
  {
    path: '/highroad',
    component: Layout,
    meta: { title: "公路专查", index: 5 },
    children: [
      {
        path: '',
        name: 'highroad',
        component: () => import('@/views/highroad/index.vue'),
        meta: { title: "公路专查", index: 5 }
      },
      {
        path: '/highroad/details/:id',
        name: 'highroadDetails',
        component: () => import('@/views/highroad/details.vue'),
        meta: { title: "公路专查", index: 5 }
      }
    ]
  },
  // 项目拟建
  {
    path: '/projectProposal',
    component: Layout,
    meta: { title: "项目拟建", index: 6 },
    children: [
      {
        path: '',
        name: 'projectProposal',
        component: () => import('@/views/projectProposal/index.vue'),
        meta: { title: "项目拟建", index: 6 }
      },
      {
        path: '/projectProposal/details/:id',
        name: 'projectProposalDetails',
        component: () => import('@/views/projectProposal/details.vue'),
        meta: { title: "项目拟建", index: 6 }
      }
    ]
  },
  // 需求广场
  {
    path: '/requirement',
    component: Layout,
    meta: { title: "需求广场", index: 6 },
    children: [
      {
        path: '',
        name: 'requirement',
        component: () => import('@/views/requirement/index.vue'),
        meta: { title: "需求广场", index: 6 }
      },
      {
        path: '/requirement/details/:id',
        name: 'requirementDetails',
        component: () => import('@/views/requirement/details.vue'),
        meta: { title: "需求广场", index: 6 }
      }
    ]
  },

  // 在线聊天
  {
    path: '/imChat',
    component: singleLayout,
    meta: { title: "在线聊天" },
    children: [
      {
        path: '',
        name: 'imChat',
        component: () => import('@/views/imChat/index.vue'),
        meta: { title: "在线聊天" }
      },

    ]
  },
  // pdf预览
  {
    path: '/pdfRead',
    component: singleLayout,
    meta: { title: "pdf预览" },
    children: [
      {
        path: '',
        name: 'pdfRead',
        component: () => import('@/views/readPdfH5/index.vue'),
        meta: { title: "pdf预览" }
      },

    ]
  },

  // 个人中心
  {
    path: '/personalCenter',
    component: personalLayout,
    meta: { title: "个人中心" },
    children: [
      {
        path: '/personalCenter/vipCenter',
        name: 'vipCenter',
        component: () => import('@/views/personalCenter/vipCenter.vue'),
        meta: { title: "会员中心" }
      },
      {
        path: '/personalCenter/orderCenter',
        name: 'orderCenter',
        component: () => import('@/views/personalCenter/orderCenter.vue'),
        meta: { title: "订单记录" }
      },
      {
        path: '/personalCenter/myCompany',
        name: 'myCompany',
        component: () => import('@/views/personalCenter/myCompany.vue'),
        meta: { title: "我的公司" }
      },
      {
        path: '/personalCenter/myPromotion',
        name: 'myPromotion',
        component: () => import('@/views/personalCenter/myPromotion.vue'),
        meta: { title: "我的推广" }
      },
      {
        path: '/personalCenter/distributionCenter',
        name: 'distributionCenter',
        component: () => import('@/views/personalCenter/distributionCenter.vue'),
        meta: { title: "分销中心" }
      },
      {
        path: '/personalCenter/chatSettings',
        name: 'chatSettings',
        component: () => import('@/views/personalCenter/chatSettings.vue'),
        meta: { title: "聊天设置" }
      },
      {
        path: '/personalCenter/accountSettings',
        name: 'accountSettings',
        component: () => import('@/views/personalCenter/accountSettings.vue'),
        meta: { title: "账号设置" }
      }
    ]
  },



  // 修改密码
  // {
  //   path: '/pwdmodify',
  //   component: Layout,
  //   meta: { title: "修改密码" },
  //   children: [
  //     { path: '', component: () => import('../views/account/PasswordModify.vue') }
  //   ]
  // },

  // 小程序配置
  // {
  //   path: '/miniprogram',
  //   component: Layout,
  //   meta: { title: "小程序配置" },
  //   children: [
  //     { path: '', component: () => import('../views/miniProgram/MiniProgram.vue') }
  //   ]
  // },

  {
    path: '/test',
    component: Layout,
    meta: { title: "测试" },
    children: [
      { path: '', component: () => import('../views/test/index.vue') }
    ]
  },


  // 多路径配置示例
  // {
  //     path: '/account',
  //     component: Layout,
  //     redirect: '/account/list',
  //     meta: { title: "账号管理" },
  //     children: [
  //         // { path: '/account/list', component: AccountList, meta: { title: "账号列表" }, },
  //         // { path: '/account/add', component: AccountAdd, meta: { title: "账号添加" }, },
  //         { path: '/account/passwordmodify', component: () => import('@/views/account/PasswordModify.vue'), meta: { title: "个人中心" }, },
  //         // { path: '/account/personal', component: () => import('@/views/account/Personal.vue'), meta: { title: "个人中心" }, },
  //     ]
  // },

]

const router = new VueRouter({
  routes
})

/* 全局路由前置守卫 */
router.beforeEach((to, from, next) => {
  next()

  // to: 要去哪里, 包含路由信息
  // from: 来自哪里, 包含路由信息
  // next: 是一个函数, 用于放行
  // console.log("我是路由保卫");

  // 查看本地是否有令牌, 有-代表登陆过, 没有-代表没有登陆过
  // let isLogin = local.get('token') ? true : false

  // 判断是否登录过
  // if (isLogin) {  // 登录过-直接放行
  // next()
  // } else {    // 没有登录过-判断是否是去登录页面
  //   let whiteList = ['/login', '/pdfRead', '/loginSuccess', '/home']
  //   if (whiteList.includes(to.path)) { // 去登录页面-直接放行
  //     next()
  //   } else {  // 不是去登录页面-跳转到登录页面
  //     next({ path: "/login" })
  //   }
  // }
})

export default router
