<template>
  <div class="im_wrapper">
    <!-- <img src="" alt=""> -->
    <div class="img_img" @click="openNewPage">
      <el-badge :value="msgNum" class="item" type="danger">
        <div style="width: 80px"></div>
      </el-badge>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msgNum: "", // 小红点
    };
  },

  // computed: {
  //   newMsg() {
  //     return localStorage.get("");
  //   },
  // },

  // watch: {},

  methods: {
    // 打开新页面
    openNewPage() {
      const routeData = this.$router.resolve("/imChat");
      window.open(routeData.href, "_blank");
    },

    // 获取新消息
    handleGetNewMsg(data) {
      console.log("handleGetNewMsg>>>>", data);
    },
  },

  created() {
    this.$bus.$on("newMsg", this.handleGetNewMsg);
  },
};
</script>

<style lang="less" scoped>
.im_wrapper {
  .img_img {
    position: fixed;
    z-index: 100;
    bottom: 15%;
    right: 8%;
    width: 80px;
    height: 80px;
    background-color: aquamarine;
    border-radius: 50%;
    cursor: pointer;
  }
  /deep/sup {
    line-height: 12px !important;
  }
}
</style>
