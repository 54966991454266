<template>
  <div class="sidebar">
    <div
      class="sidebar_menu"
      v-for="item in menu"
      :key="item.name"
      @click="handleClick(item.path)"
    >
      <img
        class="menu_icon"
        :src="`https://www.zizhihelp.com/upload/static/${item.iconUrl}.png`"
        alt=""
      />
      <div class="menu_name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
/* 侧边栏 */
export default {
  name: "Sidebar",

  data() {
    return {
      menu: [
        {
          name: "我的",
          iconUrl: "side_wode",
          hoverUrl: "side_wode_hover",
          path: "/personalCenter/vipCenter",
        },
        {
          name: "消息",
          iconUrl: "side_xiaoxi",
          hoverUrl: "side_xiaoxi_hover",
          path: "/imChat",
        },
        {
          name: "入驻",
          iconUrl: "side_ruzhu",
          hoverUrl: "side_ruzhu_hover",
          path: "/personalCenter/myCompany",
        },
        {
          name: "顶部",
          iconUrl: "side_fanhui",
          hoverUrl: "side_fanhui_hover",
          path: "",
        },
        // { name: "反馈", iconUrl: "", path: "" },
      ],

      hoverIndex: null,
    };
  },

  methods: {
    /* 操作 */
    // 侧边栏点击事件-跳转页面（新开窗口）
    handleClick(path) {
      // this.$router.push({ path });
      if (path) {
        const routeData = this.$router.resolve(path);
        window.open(routeData.href, "_blank");
      } else {
        // console.log("回到顶部", document.documentElement);
        document.querySelector("#layout").scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sidebar {
  position: fixed;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 6px 0px rgba(153, 153, 153, 0.6);
  border-radius: 16px 0px 0px 16px;
  background-color: #fff;
  // z-index: 99;
  padding: 15px 10px;

  .sidebar_menu {
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .menu_icon {
      width: 26px;
      height: 26px;
      margin-bottom: 5px;
    }

    .menu_name {
      color: #333333;
      font-size: 16px;
      line-height: 22px;
    }

    &:hover {
      border-radius: 8px;
      background-color: #eaefff;
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
