import Vue from 'vue';

// 移除el-radio的aria-hidden属性，解决切换选项时控制台报错的问题
Vue.directive('removeAriaHidden', {
  bind(el, binding) {
    const ariaEls = el.querySelectorAll('.el-radio__original')
    ariaEls.forEach((item) => {
      item.removeAttribute('aria-hidden')
    })
  }
})
